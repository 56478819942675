﻿#popin_tc_privacy {
    #popin_tc_privacy_text {
        a {
            font-size: inherit;
        }
    }

    #popin_tc_privacy_container_button {
        button, .tc-privacy-button {
            display: inline-block !important;
            margin: 0 5px 5px 5px !important;
        }
    }

    #popin_tc_privacy_btn_close {
        display: none !important;
    }
}
