﻿.disable_scrollbars {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
        /* Chrome/Safari/Webkit */
    }
}

.wrapper_touch_slider {
    cursor: grab;

    img,
    video {
        pointer-events: none;
    }

    a {
        cursor: pointer;
    }

    .line_card {
        position: relative;
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-transition: all 0.2s;
        -o-transition: all 0.2s;
        -moz-transition: all 0.2s;
        transition: all 0.2s;
        will-change: transform;
        user-select: none;
        cursor: pointer;
    }

    .line_card.active {
        cursor: grabbing;
        cursor: -webkit-grabbing;
    }

    .line_card.no-scroll {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: initial;
        gap: 1%;

        .card {
            margin: 0;
            max-width: 250px;
            width: 100%;
            min-height: 290px;
            height: auto;

            &.img {
                picture {
                    width: 100%;
                    max-width: 250px;
                    height: 100%;
                    min-height: 290px;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }

    .card {
        //min-height: 250px;
        height: 290px;
        width: 250px;
        margin: 0 10px;
        background: white;
        text-align: center;
        display: inline-block;
        vertical-align: top;

        &.text {
            padding: 20px;

            img {
                margin-left: -100px;
            }
        }

        &.img {
            img {
                vertical-align: inherit;
                pointer-events: none;
            }
        }

        q {
            display: flex;
            flex-direction: column;
            white-space: normal;
            font-family: 'Playfair Display', Arial, Helvetica, sans-serif;
            font-size: 18px;
            gap: 10px;

            &::before,
            &::after {
                display: none;
            }
        }
    }
}
