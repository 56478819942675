﻿.navigation {
    display: flex;
    //justify-content: space-between;
    justify-content: center;
    position: relative;
    font-size: .875rem;

    @media all and (max-width: 1500px) {
        justify-content: space-between;
    }

    @media all and (max-width: 64rem) {
        position: absolute;
        min-height: 100dvh;
        flex-direction: column;
        justify-content: inherit;
        overflow-y: auto;
        padding-top: 100px;
    }

    &:has(.navigation__item.-has-items:hover) {

        @media all and (min-width: 64.063rem) {
            .navigation__background {
                display: block;
            }
        }
    }

    &__background {
        @media all and (min-width: 64.063rem) {
            display: none;
            position: absolute;
            top: 47px;
            left: 0;
            width: 100%;
            height: 100dvh;
            background: #1a1a1a30;
            z-index: -1;
        }
    }

    &__item {
        padding-bottom: 0.625rem;
        //flex-grow: 1;

        @media all and (max-width: 64rem) {
            flex-grow: inherit;
        }

        @each $i in (1, 2, 3, 4, 5) {
            &.-campaign-#{$i} {
                .item__title {
                    color: var(--campaign-#{$i}-secondaryColor, white);
                    background: var(--campaign-#{$i}-mainColor, #333);
                }
            }
        }


        &:has(.nav-link[href="/offre-du-moment-sis.html"]), &:has(.nav-link[data-href="L29mZnJlLWR1LW1vbWVudC1zaXMuaHRtbA=="]) {
            .item__title {
                color: #ff352f;
            }

            &:hover, &:active, &:focus {
                .item__title {
                    color: white;
                    background: #ff352f;
                }
            }
        }

        &:hover, &:active, &:focus {
            .item__title {
                color: white;
                background: #333;
            }

            & > .nav-link:after {
                color: white;
            }

            @media all and (min-width: 64.0625rem) {
                .item__dropdown {
                    display: block;
                }
            }
        }

        @media all and (max-width: 64rem) {
            .nav-link:has(.item__title) {
                position: relative;
                display: block;

                &:after {
                    content: "5";
                    font-family: ElegantIcons;
                    position: absolute;
                    right: 0.625rem;
                    top: 50%;
                    transform: translateY(-50%);
                    font-weight: bold;
                    font-size: 1.5rem;
                }
            }
        }
    }

    &__merch {
        figcaption {
            margin-top: .3125rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: .3125rem;
        }
    }

    .item__title {
        text-align: center;
        display: block;
        width: 100%;
        padding: .625rem 2rem;

        @media all and (max-width: 1500px) {
            padding: .625rem 1rem;
        }

        @media all and (max-width: 64rem) {
            text-align: inherit;
            padding: .875rem .5rem;
        }
    }

    .item__dropdown {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: white;
        z-index: 1;

        @media all and (max-width: 64rem) {
            top: 0;
            height: 100dvh;
            padding-top: 100px;

            &.-active {
                display: block;
            }
        }

        .dropdown__wrapper {
            //border-top: solid 2px black;
            //border-bottom: solid 2px black;
            max-width: 75rem;
            margin: auto;
            display: flex;
            justify-content: center;

            @media all and (max-width: 64rem) {
                flex-direction: column;
            }
        }

        .nav-link {
            padding: .15625rem 0;

            &:not(.-no-link) {
                &:hover, &:active, &:focus {
                    @media all and (min-width: 64.0625rem) {
                        text-decoration: underline;
                    }
                }
            }
        }

        .nav-link, .nav-link b {
            display: block;
            width: 100%;
            margin: 0;
        }
    }

    .dropdown__item {
        margin-bottom: .3125rem;

        @media all and (max-width: 64rem) {
            &:not(.-custom) {
                &:hover, &:active, &:focus {
                    background: #333;

                    .nav-link, .nav-link > b, .nav-link:after {
                        color: white;
                    }
                }
            }

            .nav-link {
                padding: .625rem;
                margin-right: .625rem;
                width: calc(100% - .625rem);
            }
        }

        &.-custom {
            .nav-link {
                justify-content: center;

                &:after {
                    content: none !important;
                }
            }
        }

        .nav-link {
            display: flex;
            justify-content: space-between;
            position: relative;

            &:after {
                font-family: ElegantIcons;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                font-weight: bold;
            }

            &:not(.-no-link):after {
                @media all and (min-width: 64.0625rem) {
                    content: "E";
                }

                @media all and (max-width: 64rem) {
                    font-size: 1.5rem;
                }
            }
        }
    }

    .dropdown__subitems {
        margin-bottom: .625rem;

        @media all and (max-width: 64rem) {
            display: none;

            &.-active {
                display: block;
            }

            .nav-link:not(.-no-link) {
                &:hover, &:active, &:focus {
                    text-decoration: underline;
                }
            }
        }
    }

    .dropdown__subitem {
        margin-left: .15625rem;

        @media all and (max-width: 64rem) {
            padding: .3125rem .625rem;
        }

        &.-bold {
            * {
                font-weight: bold;
            }
        }
    }

    .dropdown__column {
        @media all and (min-width: 64.0625rem) {
            flex: 1 1 240px;
            margin: 1rem 0;
            padding: .3125rem 1rem;

            &:not(:last-of-type) {
                border-right: solid 1px #CCC;
            }
        }

        @media all and (max-width: 64rem) {
            & > div:has(.dropdown__subitem) {
                .dropdown__item {
                    .nav-link {
                        display: block;

                        &:after {
                            content: "5";
                            font-size: 1.5rem;
                            font-weight: bold;
                            transform: translateY(-50%) rotate(90deg);
                        }
                    }
                }
            }

            & > div:has(.dropdown__subitems.-active) {
                .nav-link {
                    &:after {
                        transform: translateY(-50%) rotate(-90deg);
                    }
                }
            }
        }
    }

    .logo-wrapper {
        .logo {
            padding: .3125rem;

            .nav-link {
                border: solid 1px #ddd;

                &:after {
                    content: none;
                }
            }
        }
    }

    .merch__content {
        figure {
            margin: 0;
        }
    }
}
