@mixin main-font-general {
    font-family: Roboto, Arial, Arial, Helvetica, sans-serif;
    line-height: normal;
    color: #333;
}

@mixin main-font-h1 {
    @include main-font-general;
    font-weight: bold;
    font-size: 1.875rem; // 30px
}

@mixin main-font-h2 {
    @include main-font-general;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.5625rem; // 25px
}

@mixin main-font-h3 {
    @include main-font-general;
    font-weight: bold;
    font-size: 1.375rem;
}

@mixin main-font-text {
    @include main-font-general;
    font-size: 0.875rem;
}

@mixin main-font-info {
    @include main-font-general;
    font-size: 0.75rem; // 12px
    color: #999;
}

@mixin main-font-buttons {
    @include main-font-general;
    font-size: 1.125rem !important;
}

$font-color-marketplace: #314289;
$font-color-tangerine: #ff352f;
$font-color-turcoise: #00a99d;
$font-color-error: #d21e24;
$font-color-warning: #F15A24;

.main-wrapper {
    h1, .-title-h1 {
        @include main-font-h1;
        margin: 0;
    }

    h2, h2 > a, .-title-h2, h2 > p {
        @include main-font-h2;
        margin: 0;

        &.-small {
            font-size: 1.125rem;
        }
    }

    h3, h3 > a, .-title-h3 {
        @include main-font-h3;
        margin: 0;

        &.-small {
            font-size: .875rem;
        }
    }

    h1, h2, h3 {
        &.info {
            @include main-font-info;
        }
    }

    p, a, span, ul, li, button, b {
        @include main-font-text;

        &.title-link {
            text-decoration: underline;
            text-transform: initial;

            &:after {
                font-family: 'ElegantIcons';
                content: '\0035';
            }
        }

        &.info {
            @include main-font-info;

            a, p, span, li {
                @include main-font-info;
            }
        }

        &.-small, &.-small * {
            font-size: .75rem;
        }
    }

    .button {
        p, span, a, i, b {
            @include main-font-text;
            font-size: 1rem;
        }
    }

    p, span, a, label, strong, b, i, ul, li, h1, h2, h3 {
        &.-shadow {
            text-shadow: black 1px 0 10px;
        }

        &.-marketplace {
            color: $font-color-marketplace;

            & > * {
                color: inherit;
            }
        }

        &.-tangerine {
            color: $font-color-tangerine !important;

            & > * {
                color: inherit;
            }
        }

        &.-turcoise {
            color: $font-color-turcoise;

            & > * {
                color: inherit;
            }
        }

        &.-error {
            color: $font-color-error !important;

            & > * {
                color: inherit;
            }
        }

        &.-warning {
            color: $font-color-warning !important;

            & > * {
                color: inherit;
            }
        }

        &.-white {
            color: white;

            & > * {
                color: inherit;
            }
        }
    }

    @import '../../components/_owlSlider';
    @import '../../components/_slider';
    @import '../../components/forms/_inputs';
    @import '../../components/forms/_buttons';
    @import '../../components/forms/_select';
    @import '../../components/modals/_modalTCPrivacy';
    @import '../../components/modals/_modalNewsletter';
    @import '_navigation.scss';
}
