input[type=text], input[type=tel], input[type=password], input[type=email], input[type=number] {
    border: solid 1px #999;
    font-size: .875rem;
    height: 2.8125rem;
    margin: inherit;
    padding-top: initial;
    padding-bottom: initial;
    padding-left: .625rem;
    padding-right: .625rem;
    box-shadow: none;
    margin-top: 0;
}

input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5px;
    border: solid 1px #707070;
    outline: none;
    margin: 0;
    width: 20px;
    min-width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
    aspect-ratio: 1 / 1;

    &:checked {
        &:before {
            content: '\2713';
            display: block;
            text-align: center;
            color: #333;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.2rem;
        }
    }
}

input[type=radio] {
    opacity: 1 !important;
    margin: 0;
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    border: 1px solid #333;
    border-color: #333 !important;
    background-color: white;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        left: 0.125rem;
        top: 0.125rem;
        width: .625rem;
        height: .625rem;
        background: #ff352f;
        transform: scale(0);
        border-radius: 100%;
        transition: all .5s;
    }

    &:checked {
        &:before {
            transform: scale(1);
        }
    }

    & + label {
        margin: 0;
    }
}

input[type=date] {
    border: solid 1px #999;
    font-size: .875rem;
    height: 2.8125rem;
    box-shadow: none;
}

.-toggle-password {
    position: absolute;
    background: url(/Images/sprite-password-toggle.png) left center no-repeat;
    width: 1.5625rem;
    height: 1rem;
    right: .3125rem;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);
}