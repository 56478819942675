﻿.newsletter-button {
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 99;
    transition: all .5s;

    &.-newsletter-hidden {
        transform: translateX(100%);
    }

    &:not(.-newsletter-hidden) {
        transform: translateX(1px);
    }

    & > button {
        border: solid 1px #9b9b9b !important;
        border-radius: 50px 0 0 50px;
        padding: 0 1rem !important;
        position: relative;
        background: white;
        height: 2.8125rem;
        padding-top: 0;
        padding-bottom: 0;
        display: flex !important;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        width: fit-content;

        &:hover {
            background: white !important;
        }

        img {
            width: 20px;
            height: 20px;
        }
    }
}

.newsletter-pin {
    position: absolute;
    background: #ff352f;
    height: 15px;
    width: 15px;
    color: white !important;
    font-weight: bold;
    border-radius: 100%;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 5px;
    right: 5px;
    opacity: 1;

    &.-pin-hidden {
        opacity: 0;
    }
}

#newsletter-reveal-modal {
    padding: 0;
    max-width: 95%;
    width: fit-content;
    margin-left: inherit;
    min-height: inherit;
    transform: translateX(-50%);
    left: 50%;
    top: 6.25rem !important;
    position: fixed;
}

.modal-newsletter {
    background: white;
    max-width: 100%;
    width: 42.5rem;

    .modal-newsletter__close {
        height: 1.25rem;
    }

    .modal-newsletter__wrapper {
        display: flex;

        & > * {
            flex: 1;
            max-width: 21.25rem;
            width: 100%;

            &.modal-newsletter__image {
                @media all and (max-width: 40rem) {
                    display: none;
                }
            }

            &.modal-newsletter__content {
                margin: auto;

                .content__wrapper {
                    text-align: center;
                    padding: 1.25rem;

                    .content__logo {
                        margin-bottom: 1.5625rem;
                    }

                    p:not(.-error) {
                        margin-bottom: .9375rem;
                    }

                    label, p.-error {
                        text-align: left;
                    }

                    input {
                        margin-bottom: .625rem;
                    }
                }
            }
        }
    }
}
