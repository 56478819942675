select {
    border-radius: 0px;
    border: 1px solid #999;
    display: inline-block;
    text-align: center;
    padding-top: inherit;
    padding-bottom: 0.125rem;
    height: 2.8125rem;
    //width: 3.125rem;
    width: fit-content;
    -moz-appearance: textfield;
    max-width: 100%;
    margin: 0;
    outline: 0;
    background-color: transparent;
    text-align: left;
    color: #333;
    font-size: 1rem;
    line-height: 1rem;
    font-family: Roboto,Arial,Helvetica,sans-serif !important;

    &:after {
        position: absolute;
        content: "C";
        font-family: "ElegantIcons";
        font-size: .875rem;
        right: 0;
        bottom: 0;
        top: 0;
        padding: .6rem .5rem 0 .5rem;
        color: #fff;
        pointer-events: none;
        color: gray;
    }

    &.-fw {
        width: 100%;
    }

    &.-bgtransparent {
        background: transparent;
        border-width: 0 0 1px 0;
        height: inherit;
        padding: 0 1.25rem 1px 5px;
        position: relative;
        background-repeat: no-repeat;
        background-position: center right;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgc3R5bGU9ImZpbGw6ICNmZmZmZmYiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwb2x5Z29uIHBvaW50cz0iNS45OTIsMCAyLjk5MiwzIC0wLjAwOCwwICIvPjwvc3ZnPg==);
    }

    &.-white {
        color: white;
        border-color: #ffffff55;

        option {
            color: #333;
        }
    }

    &.-w-auto {
        width: auto;
    }
}
